import React from 'react'
import "./src/styles/global.css"
import RootElement from './src/components/RootElemente'
import Layout from './src/components/Layout'

export const wrapRootElement = ({ element }) => {
     return (
        <RootElement>
          <Layout>
            {element}
          </Layout>
        </RootElement>
     )
}