import React, { ReactNode, forwardRef } from 'react'
import { primary, secondary } from '../../config/siteConfi'
import { useVisitorTracker } from '../../hooks/use-tracker-visiter'
interface MyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   children: ReactNode,
   className?: string
}

const Button = forwardRef<HTMLButtonElement, MyButtonProps>(({ children, className, onClick, ...props }, ref) => {
   const { handleClick } = useVisitorTracker()

   return (
      <button
         {...props}
         ref={ref}
         className={`p-3 rounded-full ${className}`}
         onClick={handleClick}
         style={{ background: primary ? primary : '', color: secondary ? secondary : '' }}
      >
         {children}
      </button>
   )
})

export default Button