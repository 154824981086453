import { useEffect, useRef, useState } from "react";
import axios from 'axios'

const generateUserId = () => {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
};
const getDeviceType = () => {
   const userAgent = navigator.userAgent;
   const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';
   localStorage.setItem('deviceType_2', deviceType); // Store device type in localStorage
   return deviceType;
};

const fetchCountry = async () => {
   try {
      const response = await axios.get('https://ipapi.co/json/');
      const country = response.data.country_name;
      localStorage.setItem('country_2', country);
      return country;
   } catch (error) {
      console.error('Error fetching country', error);
      return 'Unknown';
   }
};
export const useVisitorTracker = () => {
   const [templateId, setTemplateId] = useState<string | null>(null);
   const [clickCount, setClickCount] = useState<number>(0);
   const [browserInfo, setBrowserInfo] = useState<string>('');
   const hasSentVisitData = useRef(false);
   const [pages, setPages] = useState<string[]>([]);

   useEffect(() => {
      if (typeof window !== 'undefined') {
         const storedCount = localStorage.getItem('clickCount_2');
         const storedPages = localStorage.getItem('pages_2');
         const storedTemplateId = localStorage.getItem('compunyId_2');
         const currentBrowserInfo = navigator.userAgent;

         if (storedCount) {
            setClickCount(parseInt(storedCount, 10));
         }

         if (storedPages) {
            setPages(JSON.parse(storedPages));
         }
         const storedCountry = localStorage.getItem('country_2');
         const storedDeviceType = localStorage.getItem('deviceType_2');

         // If not in localStorage, fetch and store them
         if (!storedCountry) {
            fetchCountry(); // Country will be stored in localStorage
         }

         if (!storedDeviceType) {
            getDeviceType(); // Device type will be stored in localStorage
         }

         if (!storedTemplateId) {
            const newTemplateId = generateUserId();
            localStorage.setItem('compunyId_2', newTemplateId);
            setTemplateId(newTemplateId);
         } else {
            setTemplateId(storedTemplateId);
         }

         setBrowserInfo(currentBrowserInfo);
      }
   }, []);

   useEffect(() => {
      if (templateId && !hasSentVisitData.current) {
         sendVisitData(templateId, browserInfo);
         hasSentVisitData.current = true;
      }
   }, [templateId, browserInfo]);

   useEffect(() => {
      if (templateId && clickCount > 0) {
         sendVisitData(templateId, browserInfo);
      }
   }, [clickCount, templateId]);

   const trackPage = (path: string) => {
      setPages(prevPages => {
         if (!prevPages.includes(path)) {
            const newPages = [...prevPages, path];
            if (typeof window !== 'undefined') {
               localStorage.setItem('pages_2', JSON.stringify(newPages));
            }
            return newPages;
         }
         return prevPages;
      });
   };

   const sendVisitData = async (id: string, userBrowser: string) => {
      const country = localStorage.getItem('country_2') || 'Unknown'; // Retrieve country from localStorage
      const deviceType = localStorage.getItem('deviceType_2') || 'Unknown';
      const data = {
         views: [
            {
               viewerId: id,
               pages,
               clicks: clickCount,
               browser: userBrowser,
               country: country,
               device: deviceType
            }
         ]
      };

      try {
         await axios.post('https://api.mockfly.dev/mocks/339d96f2-6c3d-43f4-a01e-8f48385441e7/template', data);
      } catch (error) {
         console.error('Error sending analytics data', error);
      }
   };

   const handleClick = () => {
      setClickCount(prev => {
         const newCount = prev + 1;
         if (typeof window !== 'undefined') {
            localStorage.setItem('clickCount_2', newCount.toString());
         }
         return newCount;
      });
   };

   return { handleClick, clickCount, trackPage };
};