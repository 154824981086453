import React from 'react'
import { Link } from 'gatsby'
import { LOCAL_DATA } from '../config/siteConfi';
import Modal from './ui/Modal';
import MobileNav from './ui/MobileNav';
import { getContent } from '../lib/query';
import { useQuery } from '@tanstack/react-query';
import { useVisitorTracker } from '../hooks/use-tracker-visiter';

const NavBar = () => {
   const { isLoading, error, data } = useQuery(
      {
         queryKey: ['navdata'],
         queryFn: () => getContent(),
         staleTime: 60000, // Data is fresh for 1 minute
         refetchOnWindowFocus: true, // Refetch on window focus
         refetchOnReconnect: true, // Refetch on reconnect
      }
   )
   const NAVITEMS: string[] = (data?.siteMenu?.length ?? 0) > 0 ? data.siteMenu : LOCAL_DATA.siteMenu;

   const logo: string = data?.logotype ? data.logotype : LOCAL_DATA.logotype
   const { handleClick } = useVisitorTracker()

   return (
      <header className='py-6'>
         <nav className="container flex items-center justify-between">
            <Link to='/' onClick={handleClick}>
               <img src={logo} width={90} height={90} alt="logo" />
            </Link>
            <div className='hide-items flex items-center gap-6'>
               {NAVITEMS.map((item, index) => (
                  <Link to={`/${index === 0 ? '' : item.toLowerCase()}`} onClick={handleClick} key={index}>{item}</Link>
               ))}
            </div>
            <div className='hidden sm:block'>
               <Modal />
            </div>
            <div className='sm:hidden'>
               <MobileNav />
            </div>
         </nav>
      </header>
   )
}

export default NavBar