import React, { ReactNode } from 'react'
import NavBar from './NavBar'
import Footer from './Footer'
import { Toaster } from 'sonner'
interface LayOutProps {
   children: ReactNode
}

const Layout = ({ children }: LayOutProps) => {
   return (
      <>
         <NavBar />
         <main>
            <div className='bg-gradiante' />
            {children}
         </main>
         <Footer />
         <Toaster position="top-center" />
      </>
   )
}

export default Layout